<template>
  <div>
    <div style="text-align: right">
      <router-link :to="`/syoung/shop/transfer-account-list?accountType=${form.chargeType}&ownCompanyId=${this.$route.query.ownCompanyId}`">
        <el-button type="text">查看历史充值申请</el-button>
      </router-link>
    </div>
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-width="160px"
      class="form"
      v-loading="loading"
    >
      <el-form-item label="对方收款账户：" prop="accountIndex">
        <el-radio-group v-model="form.accountIndex" class="accountRadio">
          <el-radio v-for="(i, index) of accountData" :label="index" :key="index">
            <div class="account-info">
              <div
                v-for="{ label, prop } of accountOptions"
                :key="prop"
                class="account-info__container"
              >
                <span class="account-info__label">{{ label }}：</span>
                <span class="account-info__content">{{ i[prop] || '--' }}</span>
              </div>
            </div>
          </el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="充值类型：" prop="chargeType">
        <el-radio-group v-model="form.chargeType">
          <el-radio label="BALANCE">余额</el-radio>
          <el-radio label="DEPOSIT">保证金</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="充值主体：" prop="distributorContractInfoId">
        <el-select v-model="form.distributorContractInfoId" filterable placeholder="请选择充值主体" @change="changeChargeBank">
          <el-option
            v-for="item in contractInfoList"
            :key="item.id"
            :label="`${item.merchantTypeName}-${item.contractInfoName}`"
            :value="item.id"
          >
            <span>{{ item.merchantTypeName }}</span>-<span>{{ item.contractInfoName }}</span>
          </el-option>
        </el-select>
        <el-button type="text" @click="addBank" style="margin-left: 10px">添加主体</el-button>
      </el-form-item>

      <el-form-item label="充值账户：" prop="chargeBankId">
        <el-select v-model="form.chargeBankId" filterable placeholder="请选择户名" :disabled="!form.distributorContractInfoId">
          <el-option
            v-for="item in bankAccountOptions"
            :key="item.id"
            :label="`${item.cardholder}-${item.bankName}-${item.cardNumber}`"
            :value="item.id"
          >
            <span>{{ item.cardholder }}</span>-<span>{{ item.bankName }}</span>-<span>{{ item.cardNumber }}</span>
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item v-if="isShowTip">
        <i class="el-icon-warning icon"></i>
        <span class="tips-warning">无可用账户，请前去维护收付款信息，</span>
        <el-button type="text" @click="createdBank">去维护主体信息</el-button>
      </el-form-item>

      <el-form-item label="充值日期：" prop="chargeDate">
        <el-date-picker
          v-model="form.chargeDate"
          type="date"
          placeholder="请选择充值日期"
          value-format="timestamp"
          :pickerOptions="{
            disabledDate(date) {
              return date.getTime() > Date.now();
            },
          }"
        ></el-date-picker>
      </el-form-item>

      <el-form-item label="充值金额：" prop="chargeAmount">
        <el-input v-model="form.chargeAmount" placeholder="请输入充值金额" maxlength="9"></el-input>
      </el-form-item>

      <el-form-item label="充值凭证：" prop="chargeCertificateJson">
        <PictureCard
          :limit="2"
          :maxSize="2000 * 1024"
          v-model="form.chargeCertificateJson"
        ></PictureCard>
        <div class="el-upload__tip">最多上传2张,最大2M</div>
      </el-form-item>

      <el-form-item style="text-align: center">
        <el-button @click="onCancel">取 消</el-button>
        <el-button @click="onSubmit" type="primary">确 定</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import PictureCard from '@/components/Upload/PictureCard';
import {
  distributorChargeRecordGet,
  distributorChargeRecordCreate,
  listAccountChargeContractInfo
} from '@/api/pay';
import { listOwnCompanyInfo } from '@/api/shop';
import { validateMoneyNum } from '@/common/validator';
import pick from 'lodash/pick';

export default {
  // 线下转账充值
  name: 'transfer-account-info',
  components: {
    PictureCard,
  },
  data() {
    return {
      loading: false,
      isShowTip: false,
      form: {
        accountIndex: 0,
        accountType: 'BALANCE',
        chargeType: 'BALANCE',
        distributorContractInfoId: '',
        chargeBankId: '',
        chargeDate: '',
        chargeAmount: '',
        chargeCertificateJson: [],
      },
      rules: {
        accountIndex: [{ required: true, message: '必填信息', trigger: 'change' }],
        distributorContractInfoId: [{ required: true, message: '必填信息', trigger: 'change' }],
        chargeType: [{ required: true, message: '必填信息', trigger: 'change' }],
        chargeBankId: [{ required: true, message: '必填信息', trigger: 'change' }],
        chargeDate: [{ required: true, message: '必填信息', trigger: 'change' }],
        chargeAmount: [
          { required: true, message: '必填信息', trigger: 'blur' },
          { validator: validateMoneyNum(Math.pow(10, 9)), trigger: 'blur' },
        ],
        chargeCertificateJson: [{ required: true, message: '必填信息', trigger: 'blur' }],
      },
      bankAccountOptions: [],
      contractInfoList: [],
      accountData: []
    };
  },
  computed: {
    accountOptions() {
      return [
        {
          label: '户名',
          prop: 'ownCompanyName',
        },
        {
          label: '账号',
          prop: 'ownCompanyBandCardNumber',
        },
        {
          label: '银行',
          prop: 'ownCompanyBankName',
        },
      ];
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const { id, ownCompanyId } = this.$route.query;

      listOwnCompanyInfo()
        .then(res => {
          this.accountData = res.data.filter(item => item.ownCompanyId === ownCompanyId || '');
        })
        .finally(() => {
          if (id) {
            distributorChargeRecordGet({ id }).then(res => {
              const accountPayeeId = res.data.accountPayeeId;
              Object.assign(
                this.form,
                {
                  accountIndex: this.accountData.findIndex(
                    i => accountPayeeId === i.ownCompanyBandCardNumber
                  ),
                },
                pick(res.data || {}, ['chargeType', 'accountType', 'chargeBankId', 'chargeAmount', 'distributorContractInfoId'])
              );
              if(!this.form.distributorContractInfoId) {
                this.form.chargeBankId = '';
                return;
              }
              this.getBankAccountOptions(this.form.distributorContractInfoId);
            });
          }
        });
      // 获取账户充值主体信息
      listAccountChargeContractInfo().then(res => {
        this.contractInfoList = res.data;
      });
    },
    changeChargeBank(id) {
      this.form.chargeBankId = '';
      this.isShowTip = false;
      this.getBankAccountOptions(id);
    },
    getBankAccountOptions(id) {
      const selectedItem = this.contractInfoList.find(item => item.id === id);
      if (selectedItem) {
        this.bankAccountOptions = selectedItem?.bankCardVOList || [];
        // 充值账户只有一个时默认选中
        if (this.bankAccountOptions.length === 1) {
          this.form.chargeBankId = this.bankAccountOptions[0].id;
        }
        if(!this.bankAccountOptions.length){
          this.isShowTip = true;
        }
      } else {
        this.bankAccountOptions = [];
      }
    },
    addBank() {
      this.$router.replace('/syoung/shop/contract-info');
    },
    createdBank() {
      this.$router.replace('/syoung/shop/detail?activeName=second');
    },
    onCancel() {
      this.$router.replace('/syoung/shop/detail');
    },
    onSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true;

          const { accountIndex, ...form } = this.form;
          distributorChargeRecordCreate({
            ...form,
            accountType: this.form.chargeType,
            accountPayeeId: this.accountData[accountIndex].ownCompanyBandCardNumber,
            accountPayeeName: this.accountData[accountIndex].ownCompanyName,
            organizationName: this.accountData[accountIndex].ownCompanyBankName,
            accountSubtype: this.accountData[accountIndex].type,
            chargeCertificateJson: this.form.chargeCertificateJson.map(item => {
              return item.url;
            }),
          })
            .then(() => {
              this.$message.success('操作成功');
              this.$router.push('/syoung/shop/transfer-account-list');
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  ::v-deep {
    .el-input,
    .el-select {
      width: 380px;
    }

    .accountRadio {
      .el-radio {
        .el-radio__input {
          display: none;
        }
        .el-radio__label {
          padding-left: 0;
        }
        &.is-checked {
          .account-info {
            border: 1px solid #ab0033;
          }
        }
      }
    }
  }

  .account-info {
    padding: 5px 10px;
    box-sizing: border-box;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
    background: #f6f6f6;
    border-radius: 1px;

    &__container {
      display: flex;
    }
    &__label,
    &__content {
      display: inline-flex;
      align-items: baseline;
    }
    &__label:before {
      content: '*';
      color: #f56c6c;
      margin-right: 4px;
    }
    &__content {
      flex: 1;
      font-weight: 700;
      color: #303133;
      display: initial;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
.icon {
  margin-right: 5px;
  font-size: 14px;
  color: #e83737;
}
.tips-warning {
  font-size: 12px;
}
</style>